.login {
  display: flex;

  .ant-input {
    width: 350px;
    height: 51px;
  }

  .logo {
    width: 50%;
    height: 100vh;
    overflow: hidden;
    background-image: url("../images/login/img1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
  }

  .form-login {
    margin: auto;

    h2 {
      color: #2a3744;
      font-size: 31px;
      font-weight: 700;
      font-style: normal;
      line-height: 130%;
      margin-bottom: 50px;
    }
    p {
      margin-bottom: 0;
    }
    a {
      color: $mainColor;
      font-size: 17px;
      line-height: 18.38px;
      font-weight: 500;
    }

    .errorLogin {
      color: red;
      margin-bottom: 15px;
      margin-top: -5px;
    }

    .click-sign-up {
      margin-top: 37px;
      text-align: center;
    }
  }
}

#components-form-demo-normal-login .login-form {
  max-width: 900px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
