$logoColor: #569aff;
$bg-color: #e5e5e561;

.header {
  display: flex;
  justify-content: center;
  background-color: rgb(35, 48, 68) !important;
  align-items: center;
  .header-inner {
    background-color: rgb(35, 48, 68);
    display: flex;
    // justify-content: space-between;
    width: 1200px;
    height: 90px;
    align-items: center;
    padding: 0;

    .header-logo {
      display: flex;
      align-items: center;
      cursor: pointer;

      .logo {
        height: 40px;
      }
    }

    .header-user {
      display: flex;
      align-items: center;

      p {
        margin-right: 22px;
        margin-top: 5px;
        white-space: nowrap;
        font-weight: bold;
      }

      img {
        height: 34px;
      }

      .ant-dropdown-trigger {
        border: none !important;
      }

      .ant-menu {
        border: none;

        .ant-menu-submenu {
          margin: 0;
        }

        .ant-menu-submenu:hover {
          border: none;
          margin: 0;
        }
      }

      .ant-menu-submenu-title:hover {
        color: black;
      }

      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover {
        border: none;
      }
    }
  }
}

.content {
  // background-color: $bg-color;
  width: 100% !important;
  z-index: -1 !important;
  // height: calc(100vh - 90px);

  h1 {
    font-weight: 600;
  }
}

.hold {
  height: 90px !important;
  position: relative;
  width: 100vw;
  // left: 0;
  // bottom: 0;
  background-color: #ffffff;
  z-index: 3 !important;
  display: flex;

  justify-content: center;
  .footer-inner {
    display: flex;
    height: 90px;
    .footer-logo {
      display: flex;
      width: 100%;
      justify-content: center;
      // justify-content: space-between;
      align-items: center;
      height: 90px;
      z-index: 2 !important;
      padding: 0;
      div {
        width: 1140px;
        height: 90px;
        display: flex;
        align-items: center;

        .auth-logo {
          background-color: $logoColor;
          padding: 7px;
          border-radius: 50%;
        }

        h2 {
          margin-left: 5px;
          color: $logoColor;
        }
      }
    }

    .footer-info {
      display: flex;

      p {
        margin-left: 50px;
        margin-bottom: 0px !important;
        font-weight: bold;
      }
    }
  }
}
