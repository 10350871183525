$defaultcolor: #ffffff;

.content-container {
  display: flex;
  justify-content: center;
  width: 98%;
}

.orderpanel-container {
  justify-items: center;
  align-content: center;
  width: calc(60% - 240px);
  min-height: 70vh;

  .wrapper {
    display: flex;
    justify-content: center;
  }

  .loading-form {
    text-align: center;
  }

  span {
    // font-size: 12px;
    // opacity: 0.5;
    margin-left: 0;
  }

  .howOld {
    display: flex;
    margin-bottom: 20px;
    .years {
      margin-left: 20px;
    }
  }

  .age-status {
    .hide {
      display: none;
    }
  }

  .input {
    span {
      opacity: 1;
      font-size: 19px;
    }
  }

  .ant-input,
  .ant-dropdown-trigger,
  .ant-select-selector {
    height: 44px;
  }

  // .textarea {
  //   height: 200px;
  // }

  .summary {
    .ant-select-selector {
      height: 100px !important;
    }
  }

  .orderpanel-left-container {
    text-align: center;
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    height: calc(90vh - 80px);

    .order-lerf-item {
      h1 {
        margin: 32px auto;
        font-size: 32px;
      }

      .select-item {
        color: $mainColor;
        display: flex;
        justify-content: space-between;
        border: 1px solid $mainColor;
        border-radius: 10px;
        overflow: hidden;

        .select {
          width: 100%;
          cursor: pointer;
          text-align: center;
          padding: 10px 0;
          font-size: 18px;
          line-height: 20.68px;
          border-right: 1px solid $mainColor;

          &.referrals {
            border-right: none;
          }

          &.active {
            color: $defaultcolor;
            background-color: $mainColor;
          }
        }
      }

      .form-select-qualify {
        // height: 750px;
        .qualify-form {
          position: relative;
          .quiz-diagnostic {
            width: 100%;
            background-color: #fff;
            box-shadow: -2px 1px 3px 0px #d2d2d2;
            border-radius: 10px;
            // position: fixed;
            // top: 202px;
            // left: 20px;
            // left: -480px;
            height: 100%;
            .quiz-diagnostic-wrapper {
              margin: 10px;
              max-height: 85%;
              overflow-y: scroll;
            }
          }
        }
        // margin-top: 60px;
        text-align: start;

        .age-status {
          display: flex;
          justify-content: space-between;

          .input {
            width: 47%;
          }
        }

        .name-age-sex {
          display: flex;
          justify-content: space-between;

          .input {
            width: 47% !important;
          }
        }

        .name-age-sex-id {
          display: flex;
          justify-content: space-between;

          .input {
            width: 30% !important;
          }
        }

        .panel-detail {
          display: flex;
          justify-content: space-between;

          .form-detail {
            width: 47%;
          }
        }
      }
      .lesion-location {
        font-size: 14px;
      }
      .form-right {
        width: 47%;
      }
      .select-lesion-location {
        text-align: center;
        margin-bottom: 25px;
      }
      .img-detail {
        width: 100%;
        margin: 0 0 24px 0;
        border: 1px solid #80808026;
        border-radius: 10px;
        padding: 5px;
        margin: auto;
        padding-bottom: 30px;
        .file {
          height: 350px;
          text-align: center;
          background-image: url("../images/order/breast.png");
          background-size: 100% 350px;
          background-repeat: no-repeat;
        }
        .breast-10 {
          position: absolute;
          width: 12%;
          height: 165px;
          top: 127%;
          left: 8.9% !important;
          border-radius: 0 0 55px 0;
        }
        .breast-2 {
          position: absolute;
          width: 18.5%;
          height: 168px;
          top: 121%;
          left: 22.5% !important;
          border-radius: 0 0 0 59px;
        }
        .breast-12 {
          position: absolute;
          width: 11.4%;
          height: 87px;
          top: 684%;
          left: 9.5% !important;
          border-radius: 0 61px 0 0;
        }
        .breast-8 {
          position: absolute;
          width: 18%;
          height: 88px;
          top: 683%;
          left: 22.6% !important;
          border-radius: 63px 0 0;
        }
        .breast-1 {
          position: absolute;
          width: 18.5%;
          height: 167px;
          top: 127%;
          left: 58.3% !important;
          border-radius: 0 0 59px 0;
        }
        .breast-9 {
          position: absolute;
          width: 12%;
          height: 167px;
          top: 127%;
          left: 78.3% !important;
          border-radius: 0 0 0 51px;
        }
        .breast-7 {
          position: absolute;
          width: 18.3%;
          height: 88px;
          top: 686%;
          left: 58.5% !important;
          border-radius: 0 66px 0 0;
        }
        .breast-11 {
          position: absolute;
          width: 11%;
          height: 88px;
          top: 686%;
          left: 78.3% !important;
          border-radius: 52px 0 0 0;
        }
        .breast-4 {
          position: absolute;
          width: 8.5%;
          height: 65px;
          top: 567%;
          left: 17.5% !important;
          border-radius: 50%;
        }
        .breast-6 {
          position: absolute;
          width: 5.3%;
          height: 44px;
          top: 596%;
          left: 19.1% !important;
          border-radius: 50%;
        }
        .breast-3 {
          position: absolute;
          width: 9%;
          height: 66px;
          top: 566%;
          left: 73% !important;
          border-radius: 50%;
        }
        .breast-5 {
          position: absolute;
          width: 5.5%;
          height: 42px;
          top: 603%;
          left: 74.9% !important;
          border-radius: 50%;
        }
        .breast-15 {
          position: absolute;
          width: 1.6%;
          height: 144px;
          top: 122%;
          left: 20.9% !important;
        }
        .breast-16 {
          position: absolute;
          width: 15%;
          height: 13px;
          top: 648%;
          left: 26.2% !important;
        }
        .breast-17 {
          position: absolute;
          width: 1.8%;
          height: 62px;
          top: 766%;
          left: 20.9% !important;
        }
        .breast-18 {
          position: absolute;
          width: 8%;
          height: 12px;
          top: 645%;
          left: 9.4% !important;
        }
        .breast-19 {
          position: absolute;
          width: 1.6%;
          height: 142px;
          top: 121%;
          left: 76.7% !important;
        }
        .breast-20 {
          position: absolute;
          width: 15%;
          height: 12px;
          top: 649%;
          left: 58.34% !important;
        }
        .breast-21 {
          position: absolute;
          width: 1.5%;
          height: 61px;
          top: 768%;
          left: 76.7% !important;
        }
        .breast-22 {
          position: absolute;
          width: 8%;
          height: 13px;
          top: 644%;
          left: 81.9% !important;
        }
        .breast-13 {
          position: absolute;
          width: 4%;
          height: 51px;
          top: 123%;
          left: 84.5% !important;
        }
        .breast-14 {
          position: absolute;
          width: 4%;
          height: 53px;
          top: 117%;
          left: 11%;
        }
        .left-12 {
          position: absolute;
          left: 18.5%;
          top: 16%;
          opacity: 1 !important;
          font-size: 21px;
        }
        .left-9 {
          position: absolute;
          opacity: 1 !important;
          font-size: 21px;
          left: 3%;
          top: 613%;
        }
        .left-6 {
          position: absolute;
          opacity: 1 !important;
          font-size: 21px;
          left: 20%;
          top: 957%;
        }
        .left-3 {
          position: absolute;
          opacity: 1 !important;
          font-size: 21px;
          left: 42%;
          top: 613%;
        }
        .right-12 {
          position: absolute;
          opacity: 1 !important;
          font-size: 21px;
          top: 16%;
          left: 74%;
        }
        .right-9 {
          position: absolute;
          opacity: 1 !important;
          font-size: 21px;
          top: 613%;
          left: 52%;
        }
        .right-6 {
          position: absolute;
          opacity: 1 !important;
          font-size: 21px;
          top: 957%;
          left: 76%;
        }
        .right-3 {
          position: absolute;
          opacity: 1 !important;
          font-size: 21px;
          top: 613%;
          left: 91%;
        }
        .active {
          background-color: blue;
          opacity: 0.2;
        }
        // .canvas-img{
        //     width: 400px;
        //     height: 200px;
        // }

        // img{
        //     width: 100%;
        // }
        .img-draw {
          position: absolute;
          top: 1132%;
          left: 44%;
        }
        .clear-draw {
          font-size: 20px;
          font-weight: bold;
          display: inline;
          cursor: pointer;
          margin-top: 25px;
        }
        .clear-draw:hover {
          color: blue;
        }
        .save-draw {
          font-size: 20px;
          font-weight: bold;
          margin-left: 20px;
          display: inline;
          cursor: pointer;
          margin-top: 25px;
        }
        .save-draw:hover {
          color: blue;
        }
      }

      .procedure {
        display: flex;
        justify-content: space-between;
        .procedure-left {
          width: 47%;
        }
        .procedure-right {
          width: 47%;
        }
      }
      // .mainTreatment {
      //   // margin-top: 100px;
      // }

      .info {
        height: calc(66vh - 90px);
        overflow-y: scroll;
        margin-bottom: 20px;
        padding: 10px;
      }

      .quiz-container {
        .quiz-left {
          height: calc(62vh - 90px);
          position: sticky;
          overflow-y: scroll;
        }
      }
      .pre-op-orders-referrals-container,
      .diagnostic-container,
      .qualify-form {
        min-height: calc(62vh - 90px);
        width: 100%;

        .pre-op-orders-inner {
          display: flex;
          justify-content: space-between;
          margin-bottom: 30px;
        }

        .new-order-btn {
          border-radius: 10px;
          width: 30%;
          height: 80px;

          p {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0;
            font-size: 16px;
          }
          .green {
            color: #00a900;
            opacity: 1;
          }
        }
        .middle {
          margin: 0 auto;
        }
        .done {
          border-color: #00a900;
        }
      }
      .pre-op-orders-referrals-container-workup {
        max-height: 500px;
        .pre-op-orders-inner {
          display: flex;
          // flex-direction: row;
          justify-content: space-between;
          margin-bottom: 30px;
        }

        .new-order-btn {
          margin: 0px;
          border-radius: 10px;
          width: 200px;
          height: 80px;

          p {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0;
            font-size: 16px;
          }
        }
        .middle {
          margin-right: 30px;
        }
      }

      .pre-op-orders-container {
        span {
          font-size: large;
          opacity: 1;
        }

        h2 {
          margin-bottom: 25px;
        }

        text-align: left;
        margin-top: 30px;

        .back-btn {
          margin-bottom: 30px;
          cursor: pointer;

          span {
            margin-left: 10px;
          }
        }

        .ant-col-12 {
          margin-bottom: 25px;
        }

        img {
          width: 100%;
        }

        .age-status {
          display: flex;
          justify-content: space-between;

          .input {
            width: 47%;
          }

          span {
            position: absolute;
            float: right;
            top: 13px;
            right: 12px;
          }
        }

        thead {
          tr {
            th {
              background: none;
              background-color: #e5e5e561;
            }
          }
        }
      }
    }
  }

  .popup-comment-none {
    width: 0;
    display: none;
  }

  .orderpanel-right-container {
    margin-left: 30px;
    width: 40%;
    max-width: 700px;
    background-color: $defaultcolor;
    box-shadow: -4px 0px 15px rgba(0, 0, 0, 0.05);

    h1 {
      font-size: 22px;
    }

    textArea {
      height: 130px;
    }

    .form-select-qualify {
      margin-top: 20px;
    }

    .input {
      .bold {
        opacity: 1;
        font-weight: bold;
      }

      width: 100%;
    }

    .submit-comment {
      display: flex;
      justify-content: space-between;

      .cancel-form {
        width: 50%;
        height: 43px;

        .cancel-form-button {
          box-shadow: none;
          width: 100%;
          background: red;
          border: none;

          span {
            font-size: 14px;
            font-weight: 600;
          }
        }
      }

      .accept-form {
        width: 61%;
        height: 43px;
        .action-form-btn-group {
          display: flex;
          align-items: center;
        }

        .ant-btn-primary {
          height: 43px;
        }

        .accept-form-button {
          width: 100%;
          box-shadow: none;

          span {
            font-size: 14px;
            font-weight: 600;
            opacity: 1;
          }
        }
        .link {
          span {
            color: #4276c5 !important;
          }
        }
      }
    }
  }
}

//new-order
.submit-diagnostic {
  // margin-bottom: 80px;
  text-align: center;

  .accept-form-button {
    width: 20%;
    height: 40px;
    margin: 0px 10px;

    span {
      color: white;
      font-size: 16px;
      font-weight: bold;
      opacity: 1;
    }
  }
  .accept-form-button-link {
    width: 20%;
    height: 40px;
    margin: 0px 10px;

    span {
      color: #4276c5;
      font-size: 16px;
      font-weight: bold;
      opacity: 1;
    }
  }

  .loading-spin-btn {
    margin-left: 10px;
    display: none;
  }

  .loading-spin-btn.active {
    display: block;
  }
}
.imguploads {
  width: -webkit-fill-available;
}
.displayNone {
  display: none;
}

.receptorStatus {
  width: 47%;

  .input {
    width: 100% !important;
  }
}
.inp-commnet {
  width: 100%;
  height: 200px;
}

.question-label {
  display: flex;
  justify-content: space-between;
}

.cancel-form-button {
  border: solid 1px red !important;
  background-color: red !important;
  color: #ffffff;
}

.cancel-form-button:hover {
  border: solid 1px red !important;
  color: #ffffff;
}

.pre-question {
  cursor: pointer;
  display: flex;
}
.pre-icon {
  margin: 7px;
}

.question-group {
  margin: 20px 0px;
}

.child-question-group {
  margin: 10px 40px;
}

.quiz-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  .quiz-left {
    padding: 0px 30px;
    width: 100%;
    min-height: 500px;

    .action-radio {
      display: flex;
      justify-content: center;
      z-index: 10;
      span {
        font-size: large;
        opacity: 1;
      }

      .accept-form-button {
        width: 20%;
        height: 40px;
        margin: 0px 10px;
      }
      width: 100%;
      bottom: -50px;
      left: 30%;
    }
  }
  .other-group {
    display: flex;
    align-items: center;
  }

  .quiz-right {
    width: 18%;
    background-color: #fff;
    box-shadow: -2px 1px 3px 0px #d2d2d2;
    border-radius: 10px;
    float: right;
    position: fixed;
    top: 100px;
    right: 20px;
    height: 100%;

    .quiz-data {
      height: 82%;
      overflow-y: scroll;
      .quiz-right-wrapper {
        margin: 10px;
        padding: 0 10px;
        .quiz-view {
          margin: 20px 0px;
        }
      }
    }

    .start-over {
      text-align: center;
      span {
        font-size: large;
        opacity: 1;
      }

      .accept-form-button {
        width: 87%;
        height: 40px;
        margin: 0px 10px;
        margin-bottom: 10px;
      }
    }
  }
}
.inputquiz {
  height: 2px;
}
.formchild {
  margin-left: 22px;
}
.formciteria {
  margin-left: 25px;
  color: red;
}
