$mainColor: #4276c5;

.ant-btn-primary {
  background: $mainColor;
  width: 350px;
  height: 51px;
  border-radius: 10px;
  margin-top: 21px;

  span {
    font-size: 17px;
    font-weight: 500;
  }
}

.ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}

.ant-dropdown-trigger,
.ant-select-selector,
.ant-picker {
  min-height: 44px;
  background: none;
  width: 100%;
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

// .ant-input {
//   padding: 10px 0px !important;
// }

.ant-button > span {
  font-size: 14px !important;
}

.ant-input,
.ant-picker,
.ant-dropdown-trigger,
.ant-select-selector {
  background-color: #fff !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 10px !important;
}

.ant-card-body {
  display: flex;
}
.ant-modal-confirm-btns {
  .ant-btn-primary {
    width: 90px !important;
    height: 34px;
    border: none;
    border-radius: 2px;
    margin-bottom: 2px;
  }
}
.ant-modal-footer {
  .ant-btn-primary {
    width: 90px !important;
    height: 34px;
    border: none;
    border-radius: 2px;
    margin-bottom: 2px;
  }
}

.ant-card {
  border-radius: 10px;
}

.ant-form-item-label {
  padding: 0 !important;
  label {
    font-weight: 800;
    font-size: larger;
  }
}
.ant-select-selector {
  overflow-y: auto;
  font-size: larger;
}

.ant-statistic-content-value {
  span {
    input {
      font-size: larger;
    }
  }
  span ::after {
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  color: black;
  margin: 0;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  border-bottom: none;
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: none;
}

.ant-table tr:nth-child(2n) {
  background-color: #e5e5e561;
}

.ant-table-tbody > tr.ant-table-row-level-1:hover > td {
  background: none;
}

.ant-checkbox + span {
  padding-right: 1px;
}

.ant-radio-wrapper {
  span {
    font-size: 19px !important;
  }
}
.ant-select-selector {
  span {
    font-size: 15px !important;
  }
}
.client-sub-menu .ant-menu-submenu-title {
  color:white !important;
  font-weight: 500 !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}




