.App {
  font-family: Ubuntu !important;
}

body {
  background-color: #e5e5e561;
}

span {
  font-size: 14px;
}

.menu {
  display: flex;
  font-size: 18px;
  margin-left: 30px;
  margin-right: 30px;
  width: 25%;
  align-items: center;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  overflow: scroll !important;
  text-overflow: inherit !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.css-qiwgdb.MuiSelect-select {
  overflow: scroll !important;
  text-overflow: inherit !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.css-qiwgdb.MuiSelect-select::-webkit-scrollbar {
  display: none !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select::-webkit-scrollbar {
  display: none !important;
}

.css-qiwgdb {
  padding: 7.5px 14px !important;
}

.client-menu-link {
  padding: 5px;
  border: solid 0.2px #4276c5;
  border-radius: 10px !important;
  background-color: #569aff52 !important;
  color: white;
  cursor: pointer;
}

.client-menu-link:hover {
  background-color: #569aff !important;
}

.menu-link {
  text-decoration-line: none !important;
  border: solid 0.2px #4276c5;
  border-radius: 10px !important;
  background-color: #569aff52 !important;
}
.menu-link-active {
  background-color: #569aff !important;
  border-radius: 10px !important;
  color: white !important;
}
.menu-link-test {
  background-color: #f4d03fb4 !important;
  color: red !important;
}

.sub-menu {
  display: flex;
  width: 100%;
  align-items: center;
  // justify-content: space-between;
}

.instruction {
  color: black !important;
  font-size: larger !important;
  font-style: italic !important;
  font-weight: 800;
}

.delete {
  color: white;
  background-color: red;
}
.delete:hover {
  color: red;
  border-color: red;
}

.confirm-cancel {
  color: white;
  background-color: #4276c5;
}

.cancel {
  color: #4276c5;
  // background-color: #4276c5;
}

.wrapper-dash {
  max-width: 1140px;
  margin: auto;
}

.wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}

.css-qivjh0-MuiStepLabel-label.Mui-active {
  font-weight: bold !important;
  color: #569aff !important;
}
.css-farnub.Mui-active {
  font-weight: bold !important;
  color: #569aff !important;
  // fill: red !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #569aff !important;
  font-weight: bold !important;
  .css-117w1su-MuiStepIcon-text {
    fill: #ffffff !important;
  }
}
.css-4ff9q7.Mui-active {
  font-weight: bold !important;
  color: #569aff !important;
  .css-10psl7k {
    fill: #ffffff !important;
  }
}

.css-10psl7k {
  fill: #569aff !important;
}

.css-4ff9q7 {
  color: white !important;
}

.css-1hngkjo {
  color: white !important;
}

.css-117w1su-MuiStepIcon-text {
  fill: #569aff !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-error {
  font-weight: bold !important;
  color: red !important;
}

.css-qivjh0-MuiStepLabel-label.Mui-completed {
  font-weight: bold !important;
  color: #7ed957 !important;
}
.css-farnub.Mui-completed {
  font-weight: bold !important;
  color: #7ed957 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  font-weight: bold !important;
  color: #7ed957 !important;
}
// .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed:nth-last-child() {
//   font-weight: bold !important;
//   color: #1976d2 !important;
// }
.css-4ff9q7.Mui-completed {
  font-weight: bold !important;
  color: #7ed957 !important;
}

.css-qivjh0-MuiStepLabel-label {
  color: #ffffff !important;
  font-weight: bold !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  color: #ffffff !important;
  font-weight: bold !important;
}
.css-farnub {
  font-weight: bold !important;
}

// .datePicker {
//   display: none;
// }

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(0 0 0 / 50%) !important;
}

.side-nav {
  background: linear-gradient(
    195deg,
    rgb(66, 66, 74),
    rgb(25, 25, 25)
  ) !important;
}

.label {
  // margin: auto;
  font-weight: 800;
  font-size: larger;
  color: black;
}

.diagnostic-text {
  height: 150px !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  height: 44px;
  border-radius: 10px !important;
  border: 1px solid #d9d9d9 !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  height: 44px;
  border-radius: 10px !important;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  border: 1px solid #d9d9d9 !important;
  border-radius: 10px !important;
  height: 44px;
  // font-family: inherit !important;
}

#tooltip {
  border-radius: 10px;
  border: none;
  background-color: #4276c5;
  padding: 5px;
  color: white;
}

.search {
  border-radius: 10px;
  padding: 10px;
  height: 44px;
  border-color: #4276c5;
  outline: 0;
  border: 1px solid #4276c5;
}

.row {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.Table__itemCount {
  /* margin-top: 10px; */
  font-size: 14px;
}

.Table__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
}

.Table__visiblePagesWrapper {
  margin-left: 5px;
  margin-right: 5px;
}

.Table__pageButton {
  font-size: 15px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  min-width: 32px;
  height: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.client {
  background-color: #569aff52;
}

.center {
  align-items: center;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  text-align: left;
  margin-top: 30px;
  padding-left: 5%;
  padding-right: 5%;
}

.subtitle {
  font-size: 12px;
  font-weight: 600;
  font-style: italic;
}

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: gray;
  min-width: 32px;
  height: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.css-bshv44-MuiButtonBase-root-MuiListItem-root:hover {
  background-color: #4276c5 !important;
}
// .css-10hburv-MuiTypography-root:hover {
//   color: rgb(86, 154, 255) !important;
// }

.Table__pageButton--active {
  color: #45b3e3;
  border: 1px solid rgba(25, 118, 210, 0.5);
  font-weight: bold;
  min-width: 32px;
  height: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.cpt {
  width: 5px !important;
}

.MuiTable-root {
  border: solid 1px rgba(224, 224, 224, 1);
  border-collapse: inherit;
  border-radius: 10px !important;
}

.MuiTableBody-root {
  .css-1enotpu-MuiTableRow-root:hover {
    background-color: rgba(189, 190, 194, 0.178);
  }
  .css-1q1u3t4-MuiTableRow-root:hover {
    background-color: rgba(189, 190, 194, 0.178);
  }
  .css-177gid-MuiTableCell-root:hover {
    color: #45b3e3;
    cursor: pointer;
  }
  .css-1ex1afd-MuiTableCell-root:hover {
    color: #45b3e3;
    cursor: pointer;
  }
}

.css-qs2q9j:hover {
  background-color: #4276c5 !important;
}

.css-919eu4 {
  background-color: rgb(0 0 0 / 50%) !important;
}

//Toggle switch
$label-colour: #bbb;
$disabled-colour: #ddd;
$toggle-colour: #4276c5;
$white: #fff;
$focus-color: #ff0;

.toggle-switch {
  position: relative;
  margin-right: 10px;
  width: 80px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid $label-colour;
    border-radius: 20px;
    margin: 0;
    &:focus {
      outline: none;
      > span {
        box-shadow: 0 0 2px 5px red;
      }
    }
    > span:focus {
      outline: none;
    }
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      line-height: 34px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: attr(data-yes);
      text-transform: uppercase;
      padding-left: 10px;
      background-color: $toggle-colour;
      color: $white;
    }
  }
  &-disabled {
    background-color: $disabled-colour;
    cursor: not-allowed;
    &:before {
      background-color: $disabled-colour;
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: $label-colour;
    color: $white;
    text-align: right;
  }
  &-switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: $white;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid $label-colour;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0px;
    }
  }
  &.small-switch {
    width: 40px;
    .toggle-switch-inner {
      &:after,
      &:before {
        content: "";
        height: 20px;
        line-height: 20px;
      }
    }
    .toggle-switch-switch {
      width: 16px;
      right: 20px;
      margin: 2px;
    }
  }
  @media screen and (max-width: 991px) {
    transform: scale(0.9);
  }
  @media screen and (max-width: 767px) {
    transform: scale(0.825);
  }
  @media screen and (max-width: 575px) {
    transform: scale(0.75);
  }
}
.end .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-complete {
  color: #1976d2 !important;
}

.journey {
  background: linear-gradient(195deg, #42424a, #191919) !important;
  width: 30%;
  border-radius: 12px;
  height: 100%;
  margin-left: 30px;
  padding: 10px;
}

.css-177gid-MuiTableCell-root {
  padding: 12px !important;
}

span.MuiStepLabel-iconContainer.css-vnkopk-MuiStepLabel-iconContainer {
  color: #1976d2 !important;
}
