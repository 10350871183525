.dashboard-container {
  padding-bottom: 100px;
  margin: 0 auto;

  h1 {
    text-align: center;
    padding: 40px 0;
  }

  .dashboard-items-container {
    display: flex;
    justify-content: space-between;

    .dashboard-item {
      width: 32.33%;

      .dashboard-item-arrow {
        cursor: pointer;
        color: #bdbdbd;
        position: absolute;
        right: 45px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .ant-statistic-title {
      font-weight: bold;
      font-family: sans-serif;
      font-size: 16px;
    }

    .Stuck {
      .ant-statistic-title {
        color: #e20000;
        font-weight: bold;
      }
    }

    .Completed {
      .ant-statistic-title {
        color: #58d68d;
        font-weight: bold;
      }
    }

    .Scheduled {
      .ant-statistic-title {
        color: #566573;
        font-weight: bold;
      }
    }

    .New {
      .ant-statistic-title {
        color: #4276c5;
        font-weight: bold;
      }
    }

    .InProgress {
      .ant-statistic-title {
        color: #f4d03f;
        font-weight: bold;
      }
    }
    .BiopsyPositive {
      .ant-statistic-title {
        color: #e20000;
        font-weight: 700 !important;
      }
    }
  }

  .dashboard-orders-detail {
    h2 {
      margin-top: 30px;
      text-align: left;
    }

    .dashboard-orders-title {
      display: flex;
      justify-content: space-between;
    }

    .new-order-btn {
      margin: 30px 0;

      span {
        font-weight: bold;
      }

      button {
        width: 100px;
        height: 40px;
        border-radius: 10px;
        color: #4276c5;
        border-color: #4276c5;
      }
    }
  }

  .dashboard-table {
    // overflow-y: scroll;
    td {
      font-weight: bold;
    }

    .status-col {
      margin-left: 1000px;
    }

    .date-col {
      text-align: right;
    }

    .ant-table tr:nth-child(2n) {
      background-color: $bg-color;
    }
  }

  // .ant-table-wrapper {
  //   overflow-y: scroll;
  //   height: calc(50vh - 140px);
  // }

  .show-more-btn {
    margin-top: 30px;
    text-align: center;

    span {
      font-weight: bold;
    }

    button {
      width: 130px;
      height: 47px;
      border-radius: 10px;
      color: #4276c5;
      border-color: #4276c5;
    }
  }
}

.dashboard-table-arrow {
  color: #516578;
}
